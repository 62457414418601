export const BucketIcon = () => {
    return (
        <div className="bucket-icon-hover">
            <svg id="eOdteSHE4DV1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 28 28" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                <path d="M21.971,9.91675l-.5367,8.04995c-.2065,3.0964-.3091,4.6445-1.3183,5.5884-1.0092.945-2.562.945-5.6653.945h-.903c-3.1034,0-4.65619,0-5.66536-.945-1.00916-.9439-1.113-2.492-1.31833-5.5884L6.02734,9.91675M11.0825,12.8334l.5833,5.8333m5.25-5.8333l-.5833,5.8333" fill="none" stroke="#ec2347" strokeWidth="2" strokeLinecap="round"/>
                <g id="bucket-animation" transform="translate(4.076012,6.978964) rotate(0)">
                    <path d="M12.080078,2.5c-.3056,0-.502719-.00596-.755859.035156-.423152.067755-.824125.233857-1.171875.484375-.348369.250851-.631711.578268-.830078.958985l-.001953.001953c-.11526.222024-.174165.411064-.271485.703125l-.113281.341797-.03125.095703c-.091188.252507-.254909.469886-.472656.626953s-.477692.245093-.746094.251953h-.103516L4,6c-.552285,0-1,.447715-1,1s.447715,1,1,1h3.582031.154297h12.708984h3.388672c.552285,0,1-.447715,1-1s-.447715-1-1-1h-3.447265c-.293439.008624-.58213-.078325-.820313-.25-.238038-.171612-.411623-.416369-.496094-.697266l-.003906-.015625-.11914-.353515c-.097326-.292061-.156228-.481104-.271485-.703125v-.001953c-.198367-.38072-.483662-.708134-.832031-.958985-.348246-.250875-.750013-.416803-1.173828-.484375-.25126-.04042-.44793-.035156-.751953-.035156h-3.837891Zm0,2h3.837891c.305598,0,.415689.006536.435547.009766h.001953c.115583.018428.225359.064408.320312.132812s.17253.158017.226563.261719c.01331.025836.05256.124379.148437.412109l.103516.310547c.038476.127948.08748.25157.140625.373047h-6.591797c.028942-.066349.057339-.132798.082031-.201172l.00586-.011719.042968-.132812.113282-.337891c.09588-.28773.13513-.386273.148437-.412109v-.001953c.054-.10314.131938-.191629.226563-.259766.09495-.068404.204729-.114384.320312-.132812h.001953C11.6643,4.506537,11.774478,4.5,12.080078,4.5Z" transform="translate(-4.076012,-6.978964)" fill="#ec2347" strokeLinecap="round"/>
                </g>
            </svg>
        </div>
    )
}

export const LogoutIcon = () => {
    return (
        <div className="logout-icon-hover">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.1667 2H8.5C7.57174 2 6.6815 2.36875 6.02513 3.02513C5.36875 3.6815 5 4.57174 5 5.5V9C5 9.30942 5.12292 9.60616 5.34171 9.82496C5.5605 10.0437 5.85725 10.1667 6.16667 10.1667C6.47609 10.1667 6.77283 10.0437 6.99162 9.82496C7.21042 9.60616 7.33333 9.30942 7.33333 9V5.5C7.33333 5.19058 7.45625 4.89383 7.67504 4.67504C7.89383 4.45625 8.19058 4.33333 8.5 4.33333H20.1667C20.4761 4.33333 20.7728 4.45625 20.9916 4.67504C21.2104 4.89383 21.3333 5.19058 21.3333 5.5V21.8333C21.3333 22.1428 21.2104 22.4395 20.9916 22.6583C20.7728 22.8771 20.4761 23 20.1667 23H8.5C8.19058 23 7.89383 22.8771 7.67504 22.6583C7.45625 22.4395 7.33333 22.1428 7.33333 21.8333V18.3333C7.33333 18.0239 7.21042 17.7272 6.99162 17.5084C6.77283 17.2896 6.47609 17.1667 6.16667 17.1667C5.85725 17.1667 5.5605 17.2896 5.34171 17.5084C5.12292 17.7272 5 18.0239 5 18.3333V21.8333C5 22.7616 5.36875 23.6518 6.02513 24.3082C6.6815 24.9646 7.57174 25.3333 8.5 25.3333H20.1667C21.0949 25.3333 21.9852 24.9646 22.6415 24.3082C23.2979 23.6518 23.6667 22.7616 23.6667 21.8333V5.5C23.6667 4.57174 23.2979 3.6815 22.6415 3.02513C21.9852 2.36875 21.0949 2 20.1667 2Z" fill="#EC2347"/>
                <g id="logout-animation" transform="translate(-3,0)">
                    <path d="M2.34171 14.4917C2.12292 14.2729 2 13.9761 2 13.6667C2 13.3573 2.12292 13.0606 2.34171 12.8418C2.5605 12.623 2.85725 12.5001 3.16667 12.5001H12.0217L9.33833 9.82839C9.22955 9.71961 9.14327 9.59047 9.0844 9.44834C9.02553 9.30622 8.99523 9.15389 8.99523 9.00005C8.99523 8.84622 9.02553 8.69389 9.0844 8.55176C9.14327 8.40964 9.22955 8.2805 9.33833 8.17172C9.44711 8.06294 9.57625 7.97665 9.71838 7.91778C9.8605 7.85891 10.0128 7.82861 10.1667 7.82861C10.3205 7.82861 10.4728 7.85891 10.615 7.91778C10.7571 7.97665 10.8862 8.06294 10.995 8.17172L15.6617 12.8384C15.7679 12.9493 15.8511 13.0802 15.9067 13.2234C16.0234 13.5074 16.0234 13.826 15.9067 14.1101C15.8511 14.2533 15.7679 14.3841 15.6617 14.4951L10.995 19.1617C10.8865 19.2711 10.7575 19.3579 10.6153 19.4171C10.4732 19.4763 10.3207 19.5068 10.1667 19.5068C10.0127 19.5068 9.86016 19.4763 9.71799 19.4171C9.57582 19.3579 9.44679 19.2711 9.33833 19.1617C9.22898 19.0533 9.14219 18.9242 9.08296 18.7821C9.02373 18.6399 8.99324 18.4874 8.99324 18.3334C8.99324 18.1794 9.02373 18.0269 9.08296 17.8847C9.14219 17.7425 9.22898 17.6135 9.33833 17.5051L12.0217 14.8334H3.16667C2.85725 14.8334 2.5605 14.7105 2.34171 14.4917Z" fill="#EC2347"/>
                </g>
            </svg>
        </div>
    )
}
